(function() {

    $('document').ready(function() {

        addCloseEvent();


        // MODAL LOGIN
        var loginOpen = document.querySelectorAll('.js-login-open');
        var loginModal = document.querySelector('.js-login');

        /*loginOpen.forEach(function(item) {
            item.addEventListener('click', function(e) {
                e.preventDefault();
                modalFn.open(loginModal, this);
            });
        });*/

        $('body').on('click', '.js-login-open', function(e) {
            e.preventDefault();
            var loginModal = document.querySelector('.js-login');
            modalFn.open(loginModal, this);
        });


        // MODAL REGISTER
        var registerOpen = document.querySelectorAll('.js-register-open');
        var registerModal = document.querySelector('.js-register');

        registerOpen.forEach(function(item) {
            item.addEventListener('click', function(e) {
                e.preventDefault();
                modalFn.open(registerModal, this);
            });
        });


        // MODAL ÉCLAIREUR
        var guideOpen = document.querySelectorAll('.js-guide-open');
        var guideModal = document.querySelector('.js-guide');

        $('body').on('click', '.js-guide-open', function(e) {
            e.preventDefault();
            var current = $(this);
            var contentId = $(this).attr('data-content-id');
            var guideModal = document.querySelector('#js-guide-' + contentId);
            if (guideModal) {
                modalFn.open(guideModal, this);
            } else {
                //call Ajax to get modal data
                $.ajax({
                    url: '/ajax/eclaireur/' + contentId,
                    type: 'GET',
                    success: function(data, statut) {
                        setTimeout(function() {
                            $('#main-content').append(data);
                            var guideModal = document.querySelector('#js-guide-' + contentId);
                            modalFn.open(guideModal, current);
                            addCloseEvent()
                        }, 1000);
                    },
                    error: function(result, status, error) {
                        setTimeout(function() {
                            console.log(error);
                            createSnackbar($('.js-cases-ajax'), 'is-error', 'Le site a rencontré une erreur');
                        }, 1000);
                    }
                });
            }

        });

        // MODAL CONTACT ÉCLAIREUR
        var contactOpen = document.querySelectorAll('.js-contact-open');
        var contactModal = document.querySelector('.js-contact');
        $('body').on('click', '.js-contact-open', function(e) {
            e.preventDefault();
            var current = $(this);
            var contentId = $(this).attr('data-content-id');
            $.ajax({
                url: '/contact/' + contentId,
                type: 'GET',
                success: function(data, statut) {
                    setTimeout(function() {
                        $('#main-content').append(data);
                        var agendaModal = document.querySelector('#js-contact-' + contentId);
                        modalFn.open(agendaModal, current);
                        addCloseEvent();
                    }, 1000);
                },
                error: function(result, status, error) {
                    setTimeout(function() {
                        console.log(error);
                        createSnackbar($('.js-cases-ajax'), 'is-error', 'Le site a rencontré une erreur');
                    }, 1000);
                }
            });
        });


        // MODAL PARTICIPATION ÉVÉNEMENT
        var agendaOpen = document.querySelectorAll('.js-agenda-open');
        var agendaModal = document.querySelector('.js-agenda');

        agendaOpen.forEach(function(item) {
            item.addEventListener('click', function(e) {
                e.preventDefault();
                var current = $(this);
                var contentId = $(this).attr('data-content-id');
                $.ajax({
                    url: '/ajax/subscribe-event/' + contentId,
                    type: 'GET',
                    success: function(data, statut) {
                        setTimeout(function() {
                            $('#main-content').append(data);
                            var agendaModal = document.querySelector('#js-agenda-' + contentId);
                            modalFn.open(agendaModal, current);
                            addCloseEvent();
                        }, 1000);
                    },
                    error: function(result, status, error) {
                        setTimeout(function() {
                            console.log(error);
                            createSnackbar($('.js-cases-ajax'), 'is-error', 'Le site a rencontré une erreur');
                        }, 1000);
                    }
                });
            });
        });


        // MODAL ARTICLE
        var articleModal = document.querySelector('.js-article');

        $('body').on('click', '.js-article-modal-open', function(e) {
            e.preventDefault();
            var current = $(this);
            var contentId = $(this).attr('data-content-id');
            var articleModal = document.querySelector('#js-article-' + contentId);
            if (articleModal) {
                modalFn.open(articleModal, this);
            } else {
                //call Ajax to get modal data
                $.ajax({
                    url: '/ajax/article/' + contentId,
                    type: 'GET',
                    success: function(data, statut) {
                        setTimeout(function() {
                            $('#main-content').append(data);
                            var articleModal = document.querySelector('#js-article-' + contentId);
                            modalFn.open(articleModal, current);
                            addCloseEvent()
                        }, 1000);
                    },
                    error: function(result, status, error) {
                        setTimeout(function() {
                            console.log(error);
                            createSnackbar($('.js-cases-ajax'), 'is-error', 'Le site a rencontré une erreur');
                        }, 1000);
                    }
                });
            }
        });


        // MODAL ARTICLE AVEC VIDÉO
        var articleVideoModal = document.querySelector('.js-modal-video');

        $('body').on('click', '.js-article-modal-video-open', function(e) {
            e.preventDefault();
            modalFn.open(articleVideoModal, this);
        });


        // MODAL ARTICLE PREMIUM
        var articlePremiumModal = document.querySelector('.js-modal-premium');

        $('body').on('click', '.js-article-modal-premium-open', function(e) {
            e.preventDefault();
            modalFn.open(articlePremiumModal, this);
        });




        document.querySelectorAll('.toggle-button').forEach((btn) => {
          btn.addEventListener('click', (e) => {
            e.target.parentElement.classList.toggle('share__wrapper--active');
            e.target.classList.toggle('toggle-button--active');
          });
        })



    });
})();

function addCloseEvent() {
    var modalClose = document.querySelectorAll('.js-modal-close');
    var modal = document.querySelectorAll('.js-modal');


    // Ferme une modal lorsque l'on clique en dehors
    modal.forEach(function(item) {
        item.addEventListener('click', function(e) {
            if (e.target === this) {
                modalFn.close(item);
            }
        });
    });

    // Ferme une modal en utilisant la croix
    /*modalClose.forEach(function(item) {
        item.addEventListener('click', function(e) {
            e.preventDefault();
            var modalContainer = this.closest('.js-modal');
            modalFn.close(modalContainer);
        });
    });*/

    $('body').on('click', '.js-modal-close', function(e) {
        e.preventDefault();
        var modalContainer = this.closest('.js-modal');
        modalFn.close(modalContainer);
    });
}