(function() {

    $('document').ready(function() {

        // AJAX GLOBAL

        var loader = '<svg class="c-button__loader" version="1.1" id="loader-1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="40px" height="40px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve"> <path fill="currentColor" d="M25.251,6.461c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615V6.461z"> <animateTransform attributeType="xml" attributeName="transform" type="rotate" from="0 25 25" to="360 25 25" dur="0.6s" repeatCount="indefinite"></animateTransform></path></svg>';

        // urlLocal pour les tests WAMI
        //! TEMP
        var urlLocal = 'http://192.168.0.101/weave_icade/integration/ajax/';
        var urlPreprod = 'http://icade.bientotenligne.com/ajax/';
        var preprod = true;

        var urlAjax = preprod ? urlPreprod : urlLocal;

        // AJAX HOME

        var moreProject = document.querySelector('.js-more-project');

        if (moreProject) {
            moreProject.addEventListener('click', function(e) {
                e.preventDefault();
                var buttonContent = this.dataset.text = this.innerHTML;
                $(this).html(loader);
                var that = $(this);

                var next = $("input[name='next']");
                var url = next.val();
                $.ajax({
                    url: url,
                    type: 'GET',
                    success: function(data, statut) {
                        setTimeout(function() {
                            $('#ajax-result').append(data.html);
                            that.html(buttonContent);
                            var urlText = new URL(window.location.href);
                            if (data.next === null) {
                                that.hide();
                            } else {
                                urlText.searchParams.set('page', data.next);
                                next.attr('value', urlText.search)
                            }
                        }, 1000);
                    },
                    error: function(result, status, error) {
                        setTimeout(function() {
                            console.log(error);
                            that.html(buttonContent);
                            createSnackbar($('.js-guide-ajax'), 'is-error', 'Le site a rencontré une erreur');
                        }, 1000);
                    }
                });
            });
        }



        // AJAX CASES

        var moreCases = document.querySelector('.js-more-cases');

        if (moreCases) {
            moreCases.addEventListener('click', function(e) {
                e.preventDefault();
                var buttonContent = this.dataset.text = this.innerHTML;
                $(this).html(loader);
                var that = $(this);

                /*
                 *Here to construct url
                 */
                var next = $("input[name='next']");
                var url = next.val();

                $.ajax({
                    url: url,
                    type: 'GET',
                    success: function(data, statut) {
                        setTimeout(function() {
                            $('.js-cases-ajax').append(data.html);
                            that.html(buttonContent);
                            var urlText = new URL(window.location.href);
                            if (data.next === null) {
                                that.hide();
                            } else {
                                urlText.searchParams.set('page', data.next);
                                next.attr('value', urlText.search)
                            }
                        }, 1000);
                    },
                    error: function(result, status, error) {
                        setTimeout(function() {
                            console.log(error);
                            that.html(buttonContent);
                            createSnackbar($('.js-cases-ajax'), 'is-error', 'Le site a rencontré une erreur');
                        }, 1000);
                    }
                });
            });
        }



        // AJAX GUIDE

        var moreGuides = document.querySelector('.js-more-guide');

        if (moreGuides) {
            moreGuides.addEventListener('click', function(e) {
                e.preventDefault();
                var buttonContent = this.dataset.text = this.innerHTML;
                $(this).html(loader);
                var that = $(this);
                /*
                 *Here to construct url
                 */
                var next = $("input[name='next']");
                var url = next.val();

                $.ajax({
                    url: url,
                    type: 'GET',
                    success: function(data, statut) {
                        setTimeout(function() {
                            $('.js-guide-ajax').append(data.html);
                            that.html(buttonContent);
                            var urlText = new URL(window.location.href);
                            if (data.next === null) {
                                that.hide();
                            } else {
                                urlText.searchParams.set('page', data.next);
                                next.attr('value', urlText.search)
                            }
                        }, 1000);
                    },
                    error: function(result, status, error) {
                        setTimeout(function() {
                            console.log(error);
                            that.html(buttonContent);
                            createSnackbar($('.js-guide-ajax'), 'is-error', 'Le site a rencontré une erreur');
                        }, 1000);
                    }
                });
            });
        }
    });
})();