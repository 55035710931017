(function () {

	$('document').ready(function () {

		// LABELS FLOTTANTS

		var floatingInputs = document.querySelectorAll('.js-input-floating');

		floatingInputs.forEach(function (input) {
			var correspondingLabel = input.nextElementSibling;
			// console.log(input.id + ': ' + input.value.length)

			// Initialise les inputs au chargement
			// Utile pour les inputs pré remplis par les navigateurs
			// TODO Parfois les inputs sont détéctés comme étant vides alors que ce n'est pas le cas
			if (input.value.length > 0) {
				correspondingLabel.classList.add('is-floating')
			} else {
				if (correspondingLabel.classList.contains('is-floating')) {
					correspondingLabel.classList.remove('is-floating');
				}
			}

			input.addEventListener('blur', function () {

				// Lorsqu'on quitte le focus d'un input, on le fait flotter s'il à du texte
				if (input.value.length > 0) {
					correspondingLabel.classList.add('is-floating')
				} else {
					if (correspondingLabel.classList.contains('is-floating')) {
						correspondingLabel.classList.remove('is-floating');
					}
				}
			});
		});
	});
})();