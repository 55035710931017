(function () {

	$('document').ready(function () {

		// TAG FILTER

		var tags = document.querySelectorAll('.js-taglist a, .js-taglist button');
		var tagFilters = [];

		if (tags) {
			tags.forEach(function (tag) {
				tag.addEventListener('click', function (e) {
					e.preventDefault();

					var tagValue = this.dataset.cat;
					var allItems = document.querySelectorAll('.js-tagitems [data-cat]');
					var tagItemsContainer = document.querySelector('.js-tagitems');
					var tagItemsInfo = document.querySelector('.js-tagitems__info');

					tagItemsContainer.classList.add('is-loading');

					// Si le tag est déjà actif on le retire du tableau
					if (this.classList.contains('is-filled')) {
						this.classList.remove('is-filled');
						tagFilters = tagFilters.filter(function (elem) {
							return elem !== tagValue
						});
						if (tagFilters.length === 0) {
							allItems.forEach(function (item) {
								item.style.display = 'block';
								tagItemsContainer.classList.remove('is-loading');
							})
							return;
						}
					} else {
						this.classList.add('is-filled');
						tagFilters.push(tagValue)
					}


					allItems.forEach(function (item) {
						item.style.display = 'none';
					})

					tagFilters.forEach(function (item) {
						var filteredItems = document.querySelectorAll('.js-tagitems [data-cat="' + item + '"]');
						filteredItems.forEach(function (elem) {
							elem.style.display = 'block';
						})
					});

					// if (tagItemsInfo) {
					// 	tagItemsContainer.removeChild(tagItemsInfo);
					// }
					// if (tagItems.length === 0) {
					// 	tagItemsContainer.insertAdjacentHTML('beforeend', '<div class="title-3 u-center js-tagitems__info"><p>Il n\'y a aucun projet de cette catégorie</p></div>');
					// }

					tagItemsContainer.classList.remove('is-loading');
				});
			});
		}
	});
})();