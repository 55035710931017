var modalFn = {
	open: function (element, target) {
		if (!target.closest('.js-modal')) {
			this.lastFocus = target;
		}
		
		var activeModal = document.querySelector('.js-modal.is-active');
		if (activeModal) {
			activeModal.classList.remove('is-active');
		} else {
			document.body.classList.add('no-scroll');
		}
		element.classList.add('is-active');
	
		var tabbableElements = element.querySelectorAll('a, button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])');
	
		var firstFocus = tabbableElements[1];
		var lastFocus = tabbableElements[tabbableElements.length - 2];
	
		var trapBegin = element.querySelector('.js-modal-trap-first');
		var trapEnd = element.querySelector('.js-modal-trap-last');
	
		firstFocus.focus();
	
		if (!firstFocus.classList.contains('has-event') && !lastFocus.classList.contains('has-event')) {
			firstFocus.classList.add('has-event');
			lastFocus.classList.add('has-event');
	
			firstFocus.addEventListener('blur', function (e) {
				if (e.relatedTarget === trapBegin) {
					lastFocus.focus();
				}
			});
		
			lastFocus.addEventListener('blur', function (e) {
				if (e.relatedTarget === trapEnd) {
					firstFocus.focus();
				}
			});
		}
	},
	close: function (element) {
		element.classList.remove('is-active');
		document.body.classList.remove('no-scroll');
		if (this.lastFocus !== undefined) {
			this.lastFocus.focus();
		}
	}
}


function createSnackbar (container, type, message) {
	container.append('<div class="c-snackbar ' + type + '">' + message + '</div>');
	var snackbar = container.find('.c-snackbar');
	setTimeout(function () {
		snackbar.addClass('is-active');
	}, 100);
	setTimeout(function () {
		snackbar.removeClass('is-active');
		snackbar.on('transitionend', function () {
			snackbar.remove();
		});
	}, 5000);
}


function debounce(callback, delay){
	var timer;
	return function(){
		var args = arguments;
		var context = this;
		clearTimeout(timer);
		timer = setTimeout(function(){
			callback.apply(context, args);
		}, delay)
	}
}