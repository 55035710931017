(function () {

	$('document').ready(function () {

		// SLIDER MOBILE DE LA HOME

		var homeSlider = document.querySelector('.js-home-club-slider');

		initSlider();

		function initSlider () {
			if (window.innerWidth < 800 && homeSlider) {
				$('.js-home-club-slider').slick({
					arrows: false,
					dots: true,
					infinite: false,
					slide: '.js-home-club-slide',
					slidesToShow: 1,
					slidesToScroll: 1,
					rows: 0 // Fix le bug lorsqu'on indique le slide (https://github.com/kenwheeler/slick/issues/3207)
				});
			} 
		}
	});
})();