(function () {
	$('document').ready(function () {

		// NAV MOBILE

		var mobileOpen = document.querySelector('.js-mobile-open');
		var mobileClose = document.querySelector('.js-mobile-close');
		var mainNav = document.querySelector('.c-header__nav-wrapper');
		var header = document.querySelector('.c-header');

		function stopScroll () {
			document.body.classList.add('no-scroll');
		}

		function getBodyScrollTop () { 
			var el = document.scrollingElement || document.documentElement;
			return el.scrollTop
		 }

		var topPos = 0;
		// Ouvre la nav movile au clic sur la croix
		mobileOpen.addEventListener('click', function (e) {
			e.preventDefault();
			// Récupère la position du scroll
			topPos = getBodyScrollTop();
			mobileOpen.classList.add('is-hidden');
			mobileClose.classList.add('is-active');
			mainNav.classList.add('is-active');
			// Met le body en fixed uniquement après l'apparition du menu 
			mainNav.addEventListener('transitionend', stopScroll);
		});

		// Ferme la nav mobile au clic sur le menu hamburger
		mobileClose.addEventListener('click', function (e) {
			e.preventDefault();
			// Rétablit le scroll
			document.body.classList.remove('no-scroll');
			// Supprime l'event listener pour éviter qu'il se réexecute inutilement
			mainNav.removeEventListener('transitionend', stopScroll);
			// Rétablit la position du scroll
			window.scrollTo(0, topPos);
			mobileOpen.classList.remove('is-hidden');
			mobileClose.classList.remove('is-active');
			mainNav.classList.remove('is-active');
		});


		// CUSTOM SELECT
		// Initialise les customs select
		if ($('.js-select').length > 0) {
			$('.js-select').selectize();
		}


		// POLYFILL OBJECT-FIT
		// Pour IE11 qui ne supporte object-fit et object-contain
		var $ofiImages = $('.js-ofi');
		objectFitImages($ofiImages);


		// ACCESSIBILITY
		// Classe sur le body pour gérer les effets au focus uniquement lorsque l'utilisateur utilise la navigation au clavier
		// Supprime la classe au clic de souris
		$('body').on('click', function () {
			if ($('body').hasClass('has-user-tabbed')) {
				$('body').removeClass('has-user-tabbed');
			}
		});

		// Ajoute la classe uniquement lorsque l'on utilise la touche Tab
		$(window).on('keydown', function (e) {
			if (e.keyCode === 9 && !$('body').hasClass('has-user-tabbed')) {
				$('body').addClass('has-user-tabbed');
			}
		});
	});
})();