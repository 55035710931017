(function () {

	$('document').ready(function () {

		if($('.js-video-icade-start, .js-video-icade').length > 0) {
			// Déclenche la vidéo au clic sur la vidéo ou à l'aide du bouton play (pour les utilisateurs clavier)
			$('.js-video-icade, .js-video-icade-start').on('click', function (e) {
				// Cette classe sur le container est nécéssaire pour gérer l'overlay
				var container = this.closest('.js-video-icade-wrapper')

				// Le sélécteur pour la vidéo diffère si l'on déclenche l'évenement sur la vidéo ou sur le bouton play
				if (e.target.classList.contains('.js-video-icade') ) {
					var video = this;
				} else {
					var video = this.parentNode.querySelector('.js-video-icade');
				}

				// Masque l'overlay au clic sur la vidéo si elle est en pause
				if (video.paused) {
					video.play();
					container.classList.add('is-playing');
					// Affiche l'overlay au clic sur la vidéo si elle est en cours
				} else {
					video.pause();
					container.classList.remove('is-playing');
				}
			})
		}

		// Réaffiche l'overlay lorsque la vidéo se termine
		$('.js-video-icade').on('ended', function (e) {
			var container = this.closest('.js-video-icade-wrapper')
			container.classList.remove('is-playing');
		});
	});
})();